var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap pb-30 xs-pb-20"},[(Object.keys(_vm.outlet).length <= 0)?_c('div',{staticClass:"xs-text-center text-red t16 no-store-found-header"},[_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"text-red pb-20"},[_vm._v(_vm._s(_vm.$t('store_404_back_to_home')))])])],1):_vm._e(),_c('div',{staticClass:"store_info"},[_c('h2',{staticClass:"disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pb-20"},[_c('div',[_c('span',{staticClass:"ss-hut-ribbon-header-comp"},[_vm._v(_vm._s(_vm.$t('store_information')))])])]),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.outlet.description
          ? _vm.outlet.description.replace(/(?:\r\n|\r|\n)/g, '<br />')
          : ''
      )}})]),_c('h2',{staticClass:"disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pb-20",attrs:{"id":"contact-us"}},[_c('div',[_c('span',{staticClass:"ss-hut-ribbon-header-comp"},[_vm._v(_vm._s(_vm.$t('address_phone')))])])]),_c('div',{staticClass:"t16 text-center"},[_c('p',{staticClass:"t16 p-5"},[_vm._v(_vm._s(_vm.outlet.name))]),_c('p',{staticClass:"t16 p-5"},[_vm._v(_vm._s(_vm.outlet.address))]),(_vm.outlet.phone)?_c('p',{staticClass:"t16 p-5"},[_c('span',[_vm._v(_vm._s(_vm.$t('phone'))+":")]),_c('a',{attrs:{"href":'tel:' + _vm.outlet.phone}},[_vm._v(_vm._s(_vm.outlet.phone))])]):_vm._e()]),_c('h2',{staticClass:"disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pt-20",attrs:{"id":"opening-hours"}},[_c('div',[_c('span',{staticClass:"ss-hut-ribbon-header-comp"},[_vm._v(_vm._s(_vm.$t('opening_hours')))])])]),_c('div',{staticClass:"pt-20"},[(_vm.outlet.opening_hours)?_c('div',{staticClass:"detail-list"},_vm._l((_vm.outlet.opening_hours),function(item,index){return _c('div',{key:index,staticClass:"data-item mb-15 flex items-center flex-col md:flex-row md:item-start md:justify-center"},[_c('b',{staticClass:"day"},[_vm._v(_vm._s(_vm.formatMonth(item.day)))]),_c('div',{staticClass:"capitalize disposition"},[_vm._v(" "+_vm._s(_vm.getDisposition(_vm.outlet.disposition))+" ")]),_c('div',{staticClass:"time-list"},[(item.opening && item.closing)?_c('div',{staticClass:"open"},[_vm._v(" "+_vm._s(_vm.formatTime(item.opening))+" – "+_vm._s(_vm.formatTime(item.closing))+" ")]):_vm._e(),_vm._l((_vm.outlet.lunch_times),function(lunch){return _c('div',[(
                lunch.day === item.day &&
                  lunch.opening &&
                  lunch.closing &&
                  lunch.active === 1
              )?_c('div',{staticClass:"lunch"},[_vm._v(" ("+_vm._s(_vm.$t('hut_lunch'))+" "+_vm._s(_vm.formatTime(lunch.opening))+" – "+_vm._s(_vm.formatTime(lunch.closing))+") ")]):_vm._e()])})],2)])}),0):_vm._e()]),(_vm.outlet.huts_day)?_c('div',{staticClass:"huts-date"},[_c('h2',{staticClass:"disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pt-20",attrs:{"id":"opening-hours"}},[_c('img',{staticClass:"img-text",attrs:{"src":_vm.$const.IMAGES.HUT_TEXT_RIBBON}})]),_c('div',{staticClass:"pt-20"},[_c('div',{staticClass:"detail-list"},[_c('ul',_vm._l((_vm.outlet.huts_day),function(item,index){return _c('li',{key:index,staticClass:"day"},[_vm._v(" "+_vm._s(_vm.formatDate(item.date, 'YYYY-MM-DD', 'YYYY-MM-DD'))+" ")])}),0)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }