<template>
  <div class="wrap pb-30 xs-pb-20">
    <div
      class="xs-text-center text-red t16 no-store-found-header"
      v-if="Object.keys(outlet).length <= 0"
    >
      <router-link to="/">
        <span class="text-red pb-20">{{ $t('store_404_back_to_home') }}</span>
      </router-link>
    </div>
    <div class="store_info">
      <h2
        class="disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pb-20"
      >
        <div>
          <span class="ss-hut-ribbon-header-comp">{{
            $t('store_information')
          }}</span>
        </div>
      </h2>
      <p
        v-html="
          outlet.description
            ? outlet.description.replace(/(?:\r\n|\r|\n)/g, '<br />')
            : ''
        "
      ></p>
    </div>
    <h2
      id="contact-us"
      class="disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pb-20"
    >
      <div>
        <span class="ss-hut-ribbon-header-comp">{{ $t('address_phone') }}</span>
      </div>
    </h2>
    <div class="t16 text-center">
      <p class="t16 p-5">{{ outlet.name }}</p>
      <p class="t16 p-5">{{ outlet.address }}</p>
      <p class="t16 p-5" v-if="outlet.phone">
        <span>{{ $t('phone') }}:</span>
        <a :href="'tel:' + outlet.phone">{{ outlet.phone }}</a>
      </p>
    </div>
    <h2
      id="opening-hours"
      class="disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pt-20"
    >
      <div>
        <span class="ss-hut-ribbon-header-comp">{{ $t('opening_hours') }}</span>
      </div>
    </h2>
    <div class="pt-20">
      <div v-if="outlet.opening_hours" class="detail-list">
        <div
          v-for="(item, index) in outlet.opening_hours"
          :key="index"
          class="data-item mb-15 flex items-center flex-col md:flex-row md:item-start md:justify-center"
        >
          <b class="day">{{ formatMonth(item.day) }}</b>

          <div class="capitalize disposition">
            {{ getDisposition(outlet.disposition) }}
          </div>
          <div class="time-list">
            <div v-if="item.opening && item.closing" class="open">
              {{ formatTime(item.opening) }} – {{ formatTime(item.closing) }}
            </div>
            <div v-for="lunch in outlet.lunch_times">
              <div
                v-if="
                  lunch.day === item.day &&
                    lunch.opening &&
                    lunch.closing &&
                    lunch.active === 1
                "
                class="lunch"
              >
                ({{ $t('hut_lunch') }} {{ formatTime(lunch.opening) }} –
                {{ formatTime(lunch.closing) }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="outlet.huts_day" class="huts-date">
      <h2
        id="opening-hours"
        class="disposition-title sx-t15 xs-my-20 xs-mx-10 xs-text-center heading-hr heading-hr-slight mt10 mb5 pt-20"
      >
        <img :src="$const.IMAGES.HUT_TEXT_RIBBON" class="img-text" />
      </h2>
      <div class="pt-20">
        <div class="detail-list">
          <ul>
            <li
              v-for="(item, index) in outlet.huts_day"
              :key="index"
              class="day"
            >
              {{ formatDate(item.date, 'YYYY-MM-DD', 'YYYY-MM-DD') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { mapGetters, mapActions } from 'vuex'

const body = document.getElementsByTagName('body')[0]
let today = moment.utc()

export default {
  computed: {
    ...mapGetters({
      selectedOutlet: 'selectedOutlet',
      selectedHutOutlet: 'selectedHutOutlet'
    })
  },
  watch: {
    selectedOutletCode: function(newValue) {}
  },
  data() {
    return {
      outletCode: '',
      orderType: this.$const.CARRYOUT,
      outlet: {}
    }
  },
  beforeCreate: function() {
    body.id = 'page-hut'
  },
  beforeDestroy: function() {
    body.id = ''
  },
  mounted() {
    this.eventTrackingScreenLoad(this, {
      screen: { name: 'home.hut', type: 'home' }
    })

    let vm = this
    this.$root.$on('onChangeOrderType', ordertype => {
      vm.onChangeOrderType(ordertype)
    })

    let outletCode = this.$route.params.outlet
    if (outletCode) {
      this.outletCode = outletCode.split('-')
      if (this.outletCode.length > 0) {
        this.outletCode = this.outletCode[0]
      }
    }
    this.initData()
  },
  methods: {
    ...mapActions({
      setHutOutletId: 'setHutOutletId',
      setSelectedHutOutlet: 'setSelectedHutOutlet'
    }),
    onChangeOrderType(orderType) {
      this.orderType = orderType
    },
    initData() {
      if (!this.outletCode) {
        return false
      }

      let vm = this
      this.axios.apiOutlet.getOutLetByCode(
        {
          code: this.outletCode,
          with: 'openingHours,specialDates,hutDays,lunchTimes,publicHoliday'
        },
        function(response) {
          vm.outlet = response.data.data.item
          if (Object.keys(vm.outlet).length > 0 <= 0) {
            vm.$notify({
              type: 'warn',
              title: vm.$t('localization.not_store')
            })
          } else {
            vm.setSelectedHutOutlet(vm.outlet)
            vm.$router.replace(vm.outletCode + '-' + vm.outlet.name)
          }
        },
        function(error) {
          console.log(`error: ${error}`)
          vm.$notify({
            type: 'warn',
            title: vm.$t('localization.not_store')
          })
        }
      )
    },
    formatMonth(name) {
      return this.$t(_.toLower(name))
    },
    formatTime(time) {
      let dateFormat = today.format('YYYY-MM-DD')
      return moment
        .utc(dateFormat + ' ' + time)
        .tz(this.outlet.timezone)
        .format('HH:mm')
    },
    getDisposition(list) {
      let vm = this
      let hasDelivery = false
      let hasCarryout = false
      let str = ''
      list.forEach(function(val) {
        let flag = 0
        if (val === 'D') {
          hasDelivery = true
        } else if (val === 'C') {
          hasCarryout = true
        } else if (val === 'R') {
          str += vm.$t('eat_in')
          flag = true
        }
        if (flag) {
          str += vm.$t('dot')
        }
      })

      let value = ''
      if (hasDelivery) {
        value = vm.$t('delivery') + vm.$t('dot')
      }

      if (hasCarryout) {
        value += vm.$t('carryout') + vm.$t('dot')
      }

      value += str
      value = _.trimEnd(value, vm.$t('dot'))
      return value
    }
  }
}
</script>
